import { setupContext } from "~shared/helpers/createContext";
import { FilterDictionary } from "./FilterService";
import { FilterUpdate } from "./FilterContextProvider";

type FilterContextType = {
  filters: FilterDictionary;
  updateFilters: (updates: FilterUpdate[]) => void;
  reset: () => void;
  count: number;
};

export const { useSafeConsumer: useFilters, Context: FilterContext } =
  setupContext<FilterContextType>({
    displayName: "FilterContext",
  });
