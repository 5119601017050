import {
  DateTransformer,
  dayDateTransformer,
  monthDateTransformer,
  yearDateTransformer,
} from "./helpers";
import { Period, PeriodButton } from "./types";

export const NUMBER_TO_DAY = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  0: "Sun",
};

export const NUMBER_TO_MONTH: Record<number, string> = {
  0: "Ja",
  1: "Fe",
  2: "Ma",
  3: "Ap",
  4: "My",
  5: "Jn",
  6: "Jl",
  7: "Ag",
  8: "St",
  9: "Oc",
  10: "Nv",
  11: "Dc",
};

export const PERIOD_OPTIONS: PeriodButton[] = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Semester", value: "semester" },
  { label: "Year", value: "year" },
];
