import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../queries";
import { fetchPlotData, PlotPeriod } from "~shared/api/endpoints/Statitstics";
import { PlotQueryOptions } from "./types";

export type UsePlotDataQueryParams = {
  minDate: string;
  maxDate: string;
};

export const usePlotDataQuery = (
  period: PlotPeriod,
  params: UsePlotDataQueryParams,
  options?: PlotQueryOptions
) => {
  return useQuery({
    queryKey: [QUERIES.plot, period, { ...params }],
    queryFn: () => fetchPlotData(period, params),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
