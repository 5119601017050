import { Layout, Typography } from "antd";
import React from "react";
import styled, { css } from "styled-components";

type HeaderProps = {
  Start?: React.ReactElement;
  Content?: React.ReactElement;
  End?: React.ReactElement;
  title?: string;
  transparent?: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { Content, End, Start, title, transparent } = props;

  return (
    <StyledLayoutHeader $transparent={transparent}>
      <ActionContainer>{!!Start && Start}</ActionContainer>
      <div>
        {!!Content && Content}
        {!!title && <Title>{title}</Title>}
      </div>
      <ActionContainer>{!!End && End}</ActionContainer>
    </StyledLayoutHeader>
  );
};

const Title = styled(Typography.Text)`
  color: ${({ theme }) => theme.antd.colorWhite};
`;

const StyledLayoutHeader = styled(Layout.Header)<{ $transparent?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.antd.paddingLG}px;
  padding-right: ${({ theme }) => theme.antd.paddingLG}px;

  ${({ $transparent }) =>
    $transparent &&
    css`
      background-color: transparent;
    `}
`;

const ActionContainer = styled.div`
  width: ${({ theme }) => theme.antd.sizeXXL}px;
  display: grid;
`;
