import { DatePeriods } from "./types";
import { dayjs } from "~shared/helpers";

export const createInitialState = (): DatePeriods => {
  const date = dayjs();
  return {
    week: {
      min: date.startOf("week"),
      max: date.endOf("week"),
    },

    month: {
      min: date.startOf("month"),
      max: date.endOf("month"),
    },

    semester: {
      min:
        date.month() < 6
          ? date.startOf("year")
          : date.endOf("year").subtract(6, "months"),
      max:
        date.month() < 6
          ? date.endOf("year").subtract(6, "months")
          : date.endOf("year"),
    },

    year: {
      min: date.startOf("year"),
      max: date.endOf("year"),
    },
  };
};
