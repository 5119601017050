import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchCategories } from "../endpoints/Categories";

type CategoriesQueryparams = {
  size?: number;
};

export const useCategoriesQuery = (params?: CategoriesQueryparams) => {
  return useInfiniteQuery({
    queryKey: [QUERIES.categories, params],
    queryFn: ({ pageParam }) =>
      fetchCategories({ page: pageParam, size: params?.size ?? 50 }),
    initialPageParam: 1,
    getNextPageParam: ({ meta }) => {
      const { page, size, total } = meta;
      const totalpages = Math.ceil(total / size);

      return page ?? 1 === totalpages ? null : page + 1;
    },
    retry: false,
  });
};
