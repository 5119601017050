import { fetchHelper } from "~shared/helpers/fetchHelper";
import { PlotData, PlotPeriod } from "./types";
import QueryString from "qs";

type FetchPlotDataParams = {
  minDate: string;
  maxDate: string;
};

export const fetchPlotData = (
  period: PlotPeriod,
  params: FetchPlotDataParams
) => {
  const { maxDate, minDate } = params;

  const query = QueryString.stringify(
    {
      minDate,
      maxDate,
    },
    {
      encode: false,
      addQueryPrefix: true,
    }
  );

  return fetchHelper<PlotData[]>({
    url: `/statistics/plot/${period}${query}`,
  });
};
