import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CategoryExpense } from "~shared/api/endpoints/Statitstics";
import { EUR } from "~shared/helpers/currency";
import { Widget } from "~shared/ui";

type ExpensesCategoryListProps = {
  data: CategoryExpense[];
  buildDetailUrl: (categoryId: number) => string;
};

export const ExpensesCategoryList: React.FC<ExpensesCategoryListProps> = (
  props
) => {
  const { buildDetailUrl, data } = props;

  return (
    <Widget title="By Category:">
      {data.map((data) => (
        <Link to={buildDetailUrl(data.categoryId)}>
          <ListItem key={data.categoryId}>
            <Typography.Text>{data.categoryName}</Typography.Text>
            <Typography.Text>
              {EUR.format(parseFloat(data.total))}
            </Typography.Text>
          </ListItem>
        </Link>
      ))}
    </Widget>
  );
};

const ListItem = styled.li`
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
  padding: ${({ theme }) => theme.antd.paddingSM}px;
  cursor: pointer;
`;
