import { ResponsiveBar } from "@nivo/bar";
import { dayjs, formatDate } from "~shared/helpers";
import React from "react";
import { usePlotDataQuery } from "~shared/api/queries/statistics";
import { Widget } from "~shared/ui";
import styled from "styled-components";
import { theme } from "antd";

export const WeeklyChart = () => {
  const { data, isLoading } = usePlotDataQuery("week", {
    maxDate: formatDate(dayjs().endOf("week")),
    minDate: formatDate(dayjs().startOf("week")),
  });

  const plotData = React.useMemo(
    () =>
      data?.map((element) => ({
        date: NUMBER_TO_DAY[dayjs(element.date).day()],
        total: element.total,
      })) ?? [],
    [data]
  );

  const { token } = theme.useToken();

  return (
    <Widget title="Weekly Expenses" isLoading={isLoading}>
      <Container>
        <ResponsiveBar
          data={plotData}
          indexBy={"date"}
          keys={["total"]}
          borderRadius={8}
          colors={[`${token.colorPrimary}30`]}
          borderWidth={1}
          labelSkipHeight={1}
          padding={0.3}
          margin={{
            bottom: 35,
            top: 20,
            left: 0,
            right: 0,
          }}
          enableGridY={false}
          enableGridX={false}
        />
      </Container>
    </Widget>
  );
};

const Container = styled.div`
  width: 100%;
  height: 150px;
`;

const NUMBER_TO_DAY = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  0: "Sun",
};
