import React from "react";
import { TimeFrame } from "../hooks";
import { Flex, Typography } from "antd";
import { dayjs } from "~shared/helpers";
import styled from "styled-components";
import { EUR } from "~shared/helpers/currency";
import { Period } from "../types";

type SummaryProps = {
  period: Period;
  timeFrame: TimeFrame;
  total: number;
};

export const Summary: React.FC<SummaryProps> = (props) => {
  const { period, timeFrame, total } = props;

  return (
    <Flex vertical>
      <div>
        <DateDisplay>{getDateMessage(period, timeFrame)}</DateDisplay>
      </div>
      <Total>{EUR.format(total)}</Total>
    </Flex>
  );
};

const getDateMessage = (period: Period, timeFrame: TimeFrame) => {
  switch (period) {
    case "month":
      return dayjs(timeFrame.min).format("MMMM YYYY");
    case "year":
    case "semester":
      return `${dayjs(timeFrame.min).format("MMMM YYYY")} - ${dayjs(
        timeFrame.max
      ).format("MMMM YYYY")}`;
    case "week":
      return `${dayjs(timeFrame.min).format("DD/MM/YYYY")} - ${dayjs(
        timeFrame.max
      ).format("DD/MM/YYYY")}`;
  }
};

const DateDisplay = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.fontSizeLG}px;
`;

const Total = styled(Typography.Text).attrs({ strong: true })`
  font-size: ${({ theme }) => theme.antd.fontSizeHeading2}px;
`;
