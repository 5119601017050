import React from "react";
import styled, { css } from "styled-components";
import { FloatButton, Layout, Spin } from "antd";
import { Link } from "react-router-dom";
import { LayoutContent } from "./LayoutContent";
import { Center } from "../Center";
import { TabNavigation } from "./Navigation/TabNavigation";
import {
  BarsOutlined,
  HomeOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Header } from "./Header";

type PageLayoutProps = {
  title: string;
  padded?: boolean;
  floatingButton?: {
    icon: React.ReactNode;
    link: string;
  };
  isLoading?: boolean;
  withNavigation?: boolean;
  HeaderStart?: React.ReactElement;
  HeaderEnd?: React.ReactElement;
};

export const PageLayout: React.FC<React.PropsWithChildren<PageLayoutProps>> = (
  props
) => {
  const {
    children,
    floatingButton,
    title,
    isLoading,
    HeaderStart,
    HeaderEnd,
    padded = true,
    withNavigation = true,
  } = props;
  const isIOS = React.useRef(/iPad|iPhone|iPod/.test(navigator.userAgent));

  return (
    <Layout style={{ height: "100%" }}>
      <Header Start={HeaderStart} End={HeaderEnd} title={title} />
      {isLoading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <>
          <LayoutContent $padded={padded}>{children}</LayoutContent>
          {!!floatingButton && (
            <Link to={floatingButton.link}>
              <StyledFloatButton
                $isIOS={isIOS.current}
                icon={floatingButton.icon}
                $navigationVisible={withNavigation}
                type="primary"
              />
            </Link>
          )}
        </>
      )}
      {withNavigation && <TabNavigation isIOS={isIOS.current} links={LINKS} />}
    </Layout>
  );
};

const StyledFloatButton = styled(FloatButton)<{
  $isIOS: boolean;
  $navigationVisible: boolean;
}>`
  width: 56px;
  height: 56px;
  bottom: ${({ $isIOS }) => ($isIOS ? 100 : 80)}px;

  .ant-float-btn-body {
    .ant-float-btn-icon {
      width: 100% !important;
    }
  }
  font-size: ${({ theme }) => theme.antd.sizeLG}px;

  ${({ $navigationVisible }) =>
    !$navigationVisible &&
    css`
      bottom: 45px;
    `}
`;

const LINKS = [
  {
    Icon: HomeOutlined,
    to: "/app",
  },
  { Icon: PieChartOutlined, to: "/app/statistics" },
  { Icon: BarsOutlined, to: "/app/categories" },
];
