import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FilterDictionary, FilterKey, FilterService } from "./FilterService";
import { FilterContext } from "./FilterContext";
import cloneDeep from "lodash/cloneDeep";
import { isEmpty } from "lodash";

export type FilterUpdate = { key: FilterKey; value: any };

export const FilterProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = React.useState(() =>
    FilterService.parseFieldDictionary(new URLSearchParams(location.search))
  );

  const updateURL = React.useCallback(
    (newFilters: FilterDictionary) => {
      const searchString = FilterService.stringifyFilters(newFilters);
      navigate(`${location.pathname}?${searchString}`, { replace: true });
    },
    [navigate, location.pathname]
  );

  const updateFilters = (updates: FilterUpdate[]) => {
    const nextFilters = cloneDeep(filters);
    updates.forEach((update) => {
      nextFilters[update.key] = update.value;
    });
    updateURL(nextFilters);
    setFilters(nextFilters);
  };

  const count = React.useMemo(
    () =>
      Object.keys(filters).reduce((acc, key) => {
        return !isEmpty(filters[key as FilterKey]) ? acc + 1 : acc;
      }, 0),
    [filters]
  );

  const reset = () => {
    updateURL({});
    setFilters({});
  };

  return (
    <FilterContext.Provider value={{ filters, count, updateFilters, reset }}>
      {children}
    </FilterContext.Provider>
  );
};
