import React from "react";
import { Button, List } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FolderAddOutlined } from "@ant-design/icons";
import { useCategoriesQuery } from "~shared/api/queries";
import { EmptyData, PageLayout } from "~shared/ui";
import { CategoryListItem } from "./ui";

export const CategoriesListPage = () => {
  const navigate = useNavigate();
  const { data, hasNextPage, fetchNextPage, isLoading } = useCategoriesQuery();

  const categories = React.useMemo(
    () =>
      data?.pages
        .flat()
        .map(({ data }) => data)
        .flat() ?? [],
    [data]
  );

  return (
    <PageLayout
      title="Categories"
      padded={false}
      floatingButton={floatingButtonConfig}
      isLoading={isLoading}
    >
      {!categories.length ? (
        <EmptyData
          buttonLabel="Create Category"
          description="Seems like you don't have any categories yet, create one!"
          onClick={() => navigate("/app/categories/new")}
        />
      ) : (
        <List
          renderItem={(item) => (
            <Link to={`/app/categories/${item.id}`}>
              <CategoryListItem category={item} />
            </Link>
          )}
          dataSource={categories}
          loadMore={
            hasNextPage ? (
              <Button onClick={() => fetchNextPage()}>Load More</Button>
            ) : null
          }
        />
      )}
    </PageLayout>
  );
};

const floatingButtonConfig = {
  icon: <FolderAddOutlined />,
  link: "/app/categories/new",
};
