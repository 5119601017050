import React from "react";
import { reducer } from "./reducer";
import { createInitialState } from "./createDefaultState";
import { Direction, PeriodData } from "./types";
import { Period } from "~modules/Statistics/types";

type UsePeriodDataProps = {
  period: Period;
};

export const usePeriodData = (props: UsePeriodDataProps): PeriodData => {
  const [datePeriods, dispatch] = React.useReducer(
    reducer,
    createInitialState()
  );

  const { period } = props;

  const moveTimeFrame = React.useCallback(
    (direction: Direction) => {
      dispatch({ direction, period });
    },
    [period, dispatch]
  );

  return {
    timeFrame: datePeriods[period],
    moveTimeFrame,
  };
};
