import { ManipulateType } from "dayjs";
import { produce } from "immer";
import { Period } from "~modules/Statistics/types";
import { Direction, DatePeriods, TimeFrame } from "./types";

type Action = {
  period: Period;
  direction: Direction;
};

export const reducer = (state: DatePeriods, action: Action): DatePeriods => {
  const { week, month, semester, year } = state;

  if (!week || !month || !semester || !year) {
    return state;
  }

  switch (action.period) {
    case "week": {
      return produce(state, (draft) => {
        draft.week = updateDate(week, {
          direction: action.direction,
          period: "week",
          time: 1,
        });
      });
    }

    case "month": {
      return produce(state, (draft) => {
        draft.month = updateDate(month, {
          direction: action.direction,
          period: "month",
          time: 1,
        });
      });
    }

    case "semester": {
      return produce(state, (draft) => {
        draft.semester = updateDate(semester, {
          direction: action.direction,
          period: "month",
          time: 6,
        });
      });
    }

    case "year": {
      return produce(state, (draft) => {
        draft.year = updateDate(year, {
          direction: action.direction,
          period: "year",
          time: 1,
        });
      });
    }
    default: {
      return state;
    }
  }
};

type UpdateConfig = {
  time: number;
  period: ManipulateType;
  direction: Direction;
};

const updateDate = (timeframe: TimeFrame, config: UpdateConfig): TimeFrame => {
  const isBackwards = config.direction === "backwards";

  return {
    min: isBackwards
      ? timeframe.min.subtract(config.time, config.period)
      : timeframe.min.add(config.time, config.period),
    max: isBackwards
      ? timeframe.max.subtract(config.time, config.period)
      : timeframe.max.add(config.time, config.period),
  };
};
