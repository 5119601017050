import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchTransactions } from "../endpoints/Transactions";
import { FilterDictionary } from "~shared/contexts/FiltersContext/FilterService";

type TransactionsQueryparams = {
  page: number;
  limit: number;
  filters?: FilterDictionary;
};

export const useTransactionsQuery = (params: TransactionsQueryparams) => {
  return useInfiniteQuery({
    queryKey: [QUERIES.transactions, { ...params }],
    queryFn: ({ pageParam }) =>
      fetchTransactions({
        page: pageParam,
        limit: params.limit,
        filters: params.filters,
      }),
    initialPageParam: params.page ?? 1,
    getNextPageParam: ({ meta }) => {
      const { page, size, total } = meta;
      const totalpages = Math.ceil(total / size);

      return page === totalpages ? null : page + 1;
    },
    retry: false,
  });
};
