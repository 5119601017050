import { Dayjs } from "dayjs";
import { dayjs } from "~shared/helpers";
import { Filter } from "./FilterService";

export const dateTransformers = (key: string) => {
  return {
    decoder: (filters: Filter[]) => {
      const [property, operator] = key.split(":");
      const searchFilter = filters.find(
        (filter) => filter.property === property && filter.operator === operator
      )?.value;

      return searchFilter ? dayjs(searchFilter) : null;
    },
    encoder: (date: Dayjs) => {
      const value = date.utc().format("YYYY-MM-DD");
      return `${key}:${value}`;
    },
  };
};

export const numericArrayTransformer = (key: string) => {
  return {
    decoder: (filters: Filter[]) => {
      const [property, operator] = key.split(":");

      return filters
        .filter(
          (filter) =>
            filter.property === property && filter.operator === operator
        )
        .map((filter) => parseInt(filter.value, 10));
    },
    encoder: (values: any[]) => {
      return values.map((value) => `${key}:${value}`);
    },
  };
};
