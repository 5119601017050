import { Card } from "antd";
import { dayjs } from "~shared/helpers";
import React from "react";
import {
  useCategoriesQuery,
  useTransactionMutation,
} from "~shared/api/queries";
import { useUserContext } from "~shared/contexts/UserContext";
import { FormLayout } from "~shared/ui";
import { TransactionFields, TransactionForm } from "./TransactionsForm";
import { useForm } from "antd/es/form/Form";
import { toFixed2String } from "~shared/helpers";
import { useNavigate } from "react-router-dom";

export const TransactionsFormPage: React.FC = () => {
  const [form] = useForm<TransactionFields>();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const { mutateAsync, isPending } = useTransactionMutation();
  const { data: categoriesData } = useCategoriesQuery();

  const options = React.useMemo(() => {
    const categories =
      categoriesData?.pages
        .flat()
        .map(({ data }) => data)
        .flat() ?? [];

    return categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [categoriesData]);

  React.useEffect(() => {
    if (categoriesData && !form.getFieldValue("categoryId")) {
      form.setFieldsValue({
        categoryId: options[0].value,
        date: dayjs(),
        time: dayjs(),
      });
    }
  }, [categoriesData, form, options]);

  const handleOnFinish = async (values: TransactionFields) => {
    const { date, time, amount, ...rest } = values;

    const parsedDate = date.format("YYYY-MM-DD");
    const parsedTime = time.format("HH:mm:ss");
    const dateTime = dayjs(`${parsedDate}T${parsedTime}`);

    const payload = {
      ...rest,
      date: dateTime.utc().format(),
      userId: user!.id,
      amount: toFixed2String(amount),
    };

    await mutateAsync(payload);
    navigate("/app");
  };

  return (
    <FormLayout backUrl="/app" title="Create Transaction">
      <Card>
        <TransactionForm
          form={form}
          primaryButtonLabel="Create"
          categories={options}
          isDisabled={isPending}
          onFinish={handleOnFinish}
        />
      </Card>
    </FormLayout>
  );
};
