import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../queries";
import { fetchTotalExpenses } from "~shared/api/endpoints/Statitstics";
import { ExtendQueryOptions } from "~shared/api/types";

type UseTotalExpensesQueryParams = {
  minDate: string;
  maxDate: string;
};

export const useTotalExpensesQuery = (
  params: UseTotalExpensesQueryParams,
  options?: ExtendQueryOptions<{ total: number }>
) => {
  return useQuery({
    queryKey: [QUERIES.totalExpenses, { ...params }],
    queryFn: () => fetchTotalExpenses(params),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
