import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../queries";
import { StatisticsConfig } from "~shared/api/endpoints/Statitstics";
import { ExtendQueryOptions } from "~shared/api/types";
import { fetchStatisticsConfig } from "~shared/api/endpoints/Statitstics/fetchStatisticsConfig";

export const useStatisticsConfig = (
  options?: ExtendQueryOptions<StatisticsConfig>
) => {
  return useQuery({
    queryKey: [QUERIES.statisticsConfig],
    queryFn: () => fetchStatisticsConfig(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
