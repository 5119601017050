import { PaginatedResponse } from "~shared/api/types";
import { fetchHelper } from "~shared/helpers/fetchHelper";
import { Category } from "./types";
import QueryString from "qs";

type FetchCategoriesParams = {
  page?: number;
  size?: number;
};

export const fetchCategories = (params: FetchCategoriesParams) => {
  const { page = 1 } = params;

  const search = QueryString.stringify(
    {
      page,
      size: params.size,
    },
    { encode: false, addQueryPrefix: true }
  );

  return fetchHelper<PaginatedResponse<Category>>({
    url: `/categories${search}`,
  });
};
