import React from "react";
import styled from "styled-components";
import { FilterOutlined } from "@ant-design/icons";
import { Badge, Button, DatePicker, Drawer, Flex, Form, Select } from "antd";
import { useCategoriesQuery } from "~shared/api/queries";
import { useFilters } from "~shared/contexts/FiltersContext";
import { FilterKey } from "~shared/contexts/FiltersContext/FilterService";
import { dayjs } from "~shared/helpers";

export const FilterDrawer: React.FC = () => {
  const { filters, updateFilters, reset, count } = useFilters();

  const { data } = useCategoriesQuery();

  const available = data?.pages[0].data.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const [state, setState] = React.useState(filters);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleApply = () => {
    const updates = Object.keys(state).map((key) => ({
      key: key as FilterKey,
      value: state[key as FilterKey],
    }));

    updateFilters(updates);
  };

  const handleReset = () => {
    setState({});
    reset();
  };

  const handleClose = () => {
    setState(filters);
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Badge count={count} size="small" offset={[-12, 12]}>
        <FilterButton onClick={() => setIsDrawerOpen(true)} />
      </Badge>
      <Drawer open={isDrawerOpen} onClose={handleClose} closable={false}>
        <Form
          layout="vertical"
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Flex flex="1 0 auto" vertical>
            <Form.Item label="Category">
              <Select
                placeholder="Select Category"
                size="large"
                style={{ width: "100%" }}
                options={available!}
                mode="multiple"
                value={state["categoryId:eq"]}
                onChange={(nextValue) =>
                  setState({ ...state, "categoryId:eq": nextValue })
                }
              />
            </Form.Item>
            <Form.Item label="Min date">
              <DatePicker
                allowClear
                value={state["date:gte"]}
                onChange={(date) => setState({ ...state, "date:gte": date })}
              />
            </Form.Item>
            <Form.Item label="Max date">
              <DatePicker
                allowClear
                maxDate={dayjs()}
                value={state["date:lte"]}
                onChange={(date) => setState({ ...state, "date:lte": date })}
              />
            </Form.Item>
          </Flex>
          <Flex>
            <Button type="primary" onClick={handleApply}>
              Apply
            </Button>
            <Button onClick={handleReset}>Reset ({count})</Button>
          </Flex>
        </Form>
      </Drawer>
    </>
  );
};

const FilterButton = styled(FilterOutlined)`
  color: ${({ theme }) => theme.antd.colorWhite};
  justify-self: flex-start;
  display: inline-flex;
  font-size: ${({ theme }) => theme.antd.fontSizeHeading4}px;
  padding: 15px;
`;
