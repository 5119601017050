import { Layout } from "antd";
import styled from "styled-components";

export const LayoutContent = styled(Layout.Content)<{ $padded?: boolean }>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${({ theme, $padded }) =>
    $padded ? `${theme.antd.paddingXL}px ${theme.antd.paddingMD}px` : 0};
`;
