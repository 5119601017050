import React from "react";
import ReactDOM from "react-dom/client";
import { Application } from "./App";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider>
      <Application />
    </ConfigProvider>
  </React.StrictMode>
);
