import { isEmpty } from "lodash";
import qs from "qs";
import { dateTransformers, numericArrayTransformer } from "./helpers";

type ComparisonOperator = "eq" | "gt" | "lt" | "gte" | "lte" | "like";

export type Filter = {
  property: string;
  operator: ComparisonOperator;
  value: string;
};

export type FilterKey = "categoryId:eq" | "date:gte" | "date:lte";
export type FilterDictionary = Partial<Record<FilterKey, any>>;

const ALLOWED_FILTERS: Record<FilterKey, any> = {
  "categoryId:eq": numericArrayTransformer("categoryId:eq"),
  "date:gte": dateTransformers("date:gte"),
  "date:lte": dateTransformers("date:lte"),
};

export class FilterService {
  static parseFilters(searchParams: URLSearchParams): Filter[] {
    const filters: Filter[] = [];
    searchParams.getAll("filter").forEach((filterString) => {
      const [property, operator, value] = filterString.split(":");
      if (property && operator && value) {
        filters.push({
          property,
          operator: operator as ComparisonOperator,
          value,
        });
      }
    });
    return filters;
  }

  static parseFieldDictionary(searchParams: URLSearchParams) {
    const filters = FilterService.parseFilters(searchParams);

    return Object.keys(ALLOWED_FILTERS).reduce((acc, key) => {
      const decoder =
        ALLOWED_FILTERS[key as keyof typeof ALLOWED_FILTERS].decoder;

      return { ...acc, [key]: decoder(filters, key) };
    }, {} as FilterDictionary);
  }

  static transformFilters(dictionary: FilterDictionary) {
    return Object.keys(dictionary).reduce((acc, key) => {
      const values = dictionary[key as keyof typeof ALLOWED_FILTERS];
      if (isEmpty(values)) {
        return acc;
      }

      const encoder =
        ALLOWED_FILTERS[key as keyof typeof ALLOWED_FILTERS].encoder;
      return [...acc, encoder(values, key)];
    }, [] as any[]);
  }

  static stringifyFilters(dictionary: FilterDictionary): string {
    const filters = this.transformFilters(dictionary);

    return qs.stringify(
      { filter: filters },
      { encode: false, arrayFormat: "repeat" }
    );
  }
}
